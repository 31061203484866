<template>
  <div class="">
    <!-- nav -->
    <nav class="navbar navbar-expand-lg navbar-info bg-info d-none">
      <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarScroll">
        <ul class="navbar-nav mr-auto my-2 my-lg-0 navbar-nav-scroll" style="max-height: 100px;">
          <li class="nav-item active">
            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- speed_test -->
    <div class="speed_test">
      <div class="speed_test_banner">
        <div class="speed_test_title">Website Speed Test</div>
        <div class="speed_test_description">
          <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam nihil maiores tempora molestiae adipisci laboriosam pariatur, autem iure, quidem perspiciatis officia deserunt, tenetur quaerat! Similique ipsam necessitatibus non doloremque. -->
        </div>
        <form class="speed_test_form">
          <div class="form-row speed_test_form_wrapper">
            <!-- url input -->
            <div class="form-group col url_input_wrapper">
              <label for="url">Starting URL</label>
              <input type="text" class="form-control url_input_text" id="url" placeholder="ENTER your URL to Test" v-model="url">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm7.931 9h-2.764a14.67 14.67 0 0 0-1.792-6.243A8.013 8.013 0 0 1 19.931 11zM12.53 4.027c1.035 1.364 2.427 3.78 2.627 6.973H9.03c.139-2.596.994-5.028 2.451-6.974.172-.01.344-.026.519-.026.179 0 .354.016.53.027zm-3.842.7C7.704 6.618 7.136 8.762 7.03 11H4.069a8.013 8.013 0 0 1 4.619-6.273zM4.069 13h2.974c.136 2.379.665 4.478 1.556 6.23A8.01 8.01 0 0 1 4.069 13zm7.381 6.973C10.049 18.275 9.222 15.896 9.041 13h6.113c-.208 2.773-1.117 5.196-2.603 6.972-.182.012-.364.028-.551.028-.186 0-.367-.016-.55-.027zm4.011-.772c.955-1.794 1.538-3.901 1.691-6.201h2.778a8.005 8.005 0 0 1-4.469 6.201z"></path>
              </svg>
            </div>
            <!-- location button -->
            <!-- <div class="form-group location_button_wrapper">
              <label for="">From Locations</label>
              <div class="location_button"> 
                <button v-if="checkedLocation.length === 0" type="button" class="btn border" data-toggle="modal" data-target="#modal_url">
                    Location
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: #495057;transform: ;msFilter:;"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>
                </button>
                <button v-else-if="checkedLocation.length === 1" type="button" class="btn border" data-toggle="modal" data-target="#modal_url">
                    {{checkedLocation[0].name}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: #495057;transform: ;msFilter:;"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>
                </button>
                <button v-else type="button" class="btn border" data-toggle="modal" data-target="#modal_url">
                    {{checkedLocation[0].name}}+
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: #495057;transform: ;msFilter:;"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>
                </button>
              </div>
            </div> -->
            <!-- submit button -->
            <div class="col-auto d-flex align-items-center position-relative speed_test_submit_wrapper">
              <button type="submit" class="btn btn-info speed_test_submit_button" @click.prevent.stop="submitHandler" :disabled="isLoading">
                <div v-if="isLoading" class="spinner-border spinner-border-sm text-light " role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Run Test
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="transform: ;msFilter:;"><path d="M10.296 7.71 14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z"></path><path d="M6.704 6.29 5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z"></path></svg>
              </button>
            </div>
          </div>
          <!-- <p class="notice" v-if="checkedLocation.length === 0">Test Message: {{ message }}</p> -->
          <!-- <p class="notice" v-else>Test Options: <span v-for="location in checkedLocation" :key="location.id">{{location.name}}&nbsp;</span></p> -->
        </form>
      </div>
      <!-- map -->
      <div class="map_wrapper">
        <div id="mapid" v-show="showMap"></div>
      </div>
      <div class="content">
        <!-- content -->
        <div class="test_content" v-if="results.length > 0">
          <button v-if="!showMap" class="btn btn-info" @click.prevent.stop="clickShowMap">顯示地圖</button>
          <button v-if="showMap" class="btn btn-info" @click.prevent.stop="clickHideMap">隱藏地圖</button>
          <div class="text_content_head">
            <div class="test_content_info"><span>測試網址:&nbsp;</span><span class="test_content_url">{{result_url}}</span></div>
            <div class="test_content_info"><span>測試時間:&nbsp;</span><span>{{nowTime}}</span></div>
            <div class="test_content_info"><span>測試連結:&nbsp;</span><span class="copy_btn" @click.prevent.stop="clickAnchorToCopyUrl">{{anchor}}</span></div>
          </div>
          <MainCharts :chart-Options="chartOptions"/>
          <div class="ip_info">
            <div class="result_title">IP 佔比</div>
            <div class="ip_description">
              <div>IP 總數：{{ totalIp }}</div>
              <div>封禁 總數：{{ blockedAmount }}</div>
            </div>
            <div class="ip_grup">
              <div class="ip_wrapp" v-for="(ip, index) in ipList" :key="index">
                <div class="ip_name">{{ ip.ip }}</div>
                <div class="ip_value">{{ ip.percent }}%</div>
              </div>
            </div>
          </div>
          <div class="result">
            <div class="result_title">速度測試表</div>
            <ul class="list_group_header">
              <li class="list_group_header_item location_item" @click.prevent.stop="clickLocation">Location</li>
              <li class="list_group_header_item line_item">Line</li>
              <li class="list_group_header_item primary_ip_item">Primary Ip</li>
              <li class="list_group_header_item http_code_item">Http Code</li>
              <li class="list_group_header_item network_item">Speed Download</li>
              <li class="list_group_header_item total_time_item">Total Time</li>
            </ul>
            <div class="list_group" v-for="result in results" :key="result.id">
              <!-- {{ result.primary_ip }} -->
              <ul v-if="!result.primary_ip  || result.primary_ip === ''" class="list_group_content">
                <li class="list_group_content_item location_item">{{result.locate}}</li>
                <li class="list_group_content_item line_item">{{result.line}}</li>
                <li class="list_group_content_item primary_ip_item_nx">NX</li>
                <li class="list_group_content_item http_code_item">*</li>
                <li class="list_group_content_item network_item">*</li>
                <li class="list_group_content_item total_time_item">*</li>
              </ul>
              <template v-else>
                <ul v-if="result.http_code !== 0" class="list_group_content">
                  <li class="list_group_content_item location_item">{{result.locate}}</li>
                  <li class="list_group_content_item line_item">{{result.line}}</li>
                  <li class="list_group_content_item primary_ip_item">{{result.primary_ip}}</li>
                  <li class="list_group_content_item http_code_item">{{result.http_code}}</li>
                  <li class="list_group_content_item network_item">{{result.speed_download}}</li>
                  <li class="list_group_content_item total_time_item"><span v-html="renderColor(result.total_time)"></span></li>
                </ul>
                <ul v-if="result.http_code === 0 || result.http_code === ''" class="list_group_content">
                  <li class="list_group_content_item location_item">{{result.locate}}</li>
                  <li class="list_group_content_item line_item">{{result.line}}</li>
                  <li class="list_group_content_item primary_ip_item">{{result.primary_ip}}</li>
                  <li class="list_group_content_item http_code_item http_code_failure">Block</li>
                  <li class="list_group_content_item network_item http_code_failure">Block</li>
                  <li class="list_group_content_item total_time_item http_code_failure ">Block</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- location modal -->
    <div class="modal fade location_model" id="modal_url" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Location</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-check form-check-inline location" v-for="state in chinaState" :key="state.id">
              <input checked class="form-check-input" type="checkbox" name="inlineRadioOptions" :id="state.id" v-model="checkedLocation" :value="{name:state.name,id:state.id,isChecked:true}">
              <label class="form-check-label" :for="state.id">{{state.name}}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info" data-dismiss="modal">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from './../utils/helper'
import L from 'leaflet'
import chinaGeoJSON from '../../src/assets/json/chinaStateData.json'
import { v4 as uuidv4 } from 'uuid';
import MainCharts from './MainCharts.vue'
import $ from 'jquery'
import testingAPI from "./../api/testingSpeed.js";
// import axios from 'axios';


export default {
  name: 'SpeedTest',
  components: {
    MainCharts
  },
  data() {
    return {
      isLoading: false,
      url: '',
      result_url:'',
      nowTime: '',
      filterdState: [],
      chinaGeoJSON,
      chinaState: [],
      checkedLocation: [],
      isMap: false,
      map: '',
      chartOptions: {
        chart: { 
          type: "column",
          marginTop: '100'
        },
        title: {  
          text: "速度測試圖" ,
          style:{
            fontSize: '32px',
            color: 'black'
          }
        },
        xAxis: {
          categories: [
          ]
        },
        yAxis: {
          title: { text: "秒" }
        },
        plotOptions: {
          column: {
            colorByPoint: true
          }
        },
        series: [{
          data: [],
          showInLegend:false
        }]
      },
      socket: null,
      task:'',
      results: [],
      try: 0,
      timer: null, 
      parseRsultsMsg: '',
      message: 'ready to test',
      anchor:'',
      ipList: [],
      blockedAmount: 0,
      totalIp: 0,
      showMap: true,
      isFirstRender: true
    }
  },
  async created(){
    const urlName = window.location.origin
    console.log(urlName)
  },
  mounted() {
    this.chinaGeoJSON = chinaGeoJSON
  },
  methods: {
    // 送出
    async submitHandler() { 
      if(this.url.trim() === '') {
        Toast.fire({
          title: 'Enter correct Url to test',
          icon: 'warning'
        })
        return
      }
      this.isLoading = true
      await this.createTask()
    },
    // 取得時間
    getNowTime() {
      const newDate = new Date
      const nowYear = newDate.getFullYear()
      const nowMoth = newDate.getMonth() + 1 < 10? '0' + (newDate.getMonth() + 1): (newDate.getMonth() + 1)
      const nowDay = newDate.getDate() < 10? '0' + newDate.getDate(): newDate.getDate()
      const hh = newDate.getHours() < 10? '0' + newDate.getHours(): newDate.getHours()
      const mm = newDate.getMinutes() < 10? '0' + newDate.getMinutes(): newDate.getMinutes()
      const ss = newDate.getSeconds() < 10? '0' + newDate.getSeconds(): newDate.getSeconds()
      const fullTime = `${nowYear}/${nowMoth}/${nowDay} ${hh}:${mm}:${ss}`
      this.nowTime = fullTime
    },
    clickShowMap() {
      const showMap = 'show'
      this.generateLeaflet(showMap)
      this.showMap = true
    },
    clickHideMap(){
      this.showMap = false
    },
    // 待整理,產生地圖
    generateLeaflet(showMap) {
      if(this.map) {
        this.map.off()
        this.map.remove()
      }
      // this.isFirstRender = true
      this.showMap = true
      this.$nextTick(() => {
      this.map = L.map('mapid').setView([35.8617, 104.1954], 4)
        let map = this.map
        map.scrollWheelZoom.disable()
        let info = L.control();

        // 將基底圖層加入osmMap
        L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png').addTo(map);

        // 加入geoJOSN 圖層
        let geojson = L.geoJson(this.filterdState, {
          style: style,
          onEachFeature: onEachFeature
        }).addTo(map);

        info.onAdd = function (map) {
          this._div = L.DomUtil.create('div'); // create a div with a class "info"
          this.update(map);
          return this._div
        };
        function style(feature) {
          return {
            fillColor: getColor(feature.time),
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.7
          }
        }
        function getColor(d) {
          return d > 3?
          "#FF4325": d > 2?
          "#ffc107": d < 2?
          '#16982B': 'black'
        }
        // method that we will use to update the control based on feature properties passed
        info.update = function (props) {
          this._div.innerHTML = '<div style="border-radius:5px; border:2px solid '+ getColor(props.time)+';"><div class="info"><h4>Speed Test</h4>' +  (props.properties ?
            '<b>地區名稱:&nbsp;</b><b>' + props.properties.name + '</b><br /><b>連結時間:&nbsp;</b><b>' + props.time +' </b></div></div>': 
            'Hover over a state</div></div>');
        }
        info.addTo(map)
        
        // 圈選的地區
        function highlightFeature(e) {
          const layer = e.target;
          layer.setStyle({
            weight: 5,
            color: '#912384',
            dashArray: '',
            fillOpacity: 0.7
          })
          if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
          }
          info.update(layer.feature);
        }
        
        // 重置 hightlight
        function resetHighlight(e) {
          const layer = e.target
          geojson.resetStyle(e.target);
          info.update(layer.feature.properties);
        }
        
        function zoomToFeature(e) {
          map.fitBounds(e.target.getBounds());
        }

        // geoJson 圖層監聽
        function onEachFeature(feature, layer) {
          layer.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToFeature
          });
        }
        
        //右下角資訊欄
        var legend = L.control({position: 'bottomright'});
        
        legend.onAdd = function () {
          var div = L.DomUtil.create('div', 'info legend')
            div.innerHTML +=
              `<i style="background-color:#16982B;"></i>&ndash; 快 0 ~ 2 <br>
              <i style="background-color:#ffc107;"></i>&ndash; 中 2 ~ 3<br>
              <i style="background-color:#FF4325;"></i>&ndash; 慢 3+ <br>`
            return div;
        };
        legend.addTo(map);
        this.removeLeafletLogo()

        if(showMap !== 'show') {
          this.showMap = false
        }
      })
    },
    // 比對 GeoJson 與 所有的節點資訊(this.results) 最後存入 this.filterdState
    mappingGeoJsonAndResults () {
      const stateNameResults = this.filterStateName()
      let groupedPeople = this.groupArrayOfObjects(stateNameResults, "locate")
      const groupedPeopleArray = Object.values(groupedPeople)
      const finalResults = this.countAvgTime(groupedPeopleArray)

      this.filterdState = this.chinaGeoJSON.features.filter((feature) => {
        let propertiesName = feature.properties.name.indexOf('内蒙古') > -1?'内蒙古':
                              feature.properties.name.indexOf('新疆') > -1?'新疆':
                              feature.properties.name.indexOf('广西') > -1?'广西':
                              feature.properties.name.indexOf('宁夏') > -1?'宁夏':
                              feature.properties.name.indexOf('北京') > -1?'北京':
                              feature.properties.name.indexOf('天津') > -1?'天津':
                              feature.properties.name.indexOf('重庆') > -1?'重庆':
                              feature.properties.name
        let checkedName = ''
        finalResults.forEach((item)=> {
            if(item.locate.indexOf(`${propertiesName}`) > -1) {
              feature.time = item.total_time === 'NaN'?'檢測失敗':item.total_time
              feature.id = uuidv4()
              checkedName = item.locate
            }
        })
        return checkedName.indexOf(`${propertiesName}`) > -1
      })
    },
    // 
    groupArrayOfObjects(list, key) {
      return list.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    // 將取得的結果(this.results) 的locate 名稱 整理成只有 省份
    filterStateName(){
      let newResults = []
      this.results.forEach((item) => {
        let newResultsObject = {
          ...item
        }
        let startIndex = item.locate.indexOf(" ")
        let tmpName = item.locate.slice(startIndex+1)
        let endIndex = tmpName.indexOf(" ")
        let newResultsItemName = tmpName.slice(0,endIndex)
        newResultsObject.locate = newResultsItemName        
        newResults.push(newResultsObject)
      })
      // console.log('newResults:', newResults)
      return newResults
    },
    // 計算重複省會的 平均時間
    countAvgTime(array){
      const tmpArray = []
      array.forEach((item) => {
        const groupName = item[0].locate
        let tmpTime = []
        let sum = 0
        let tmpObject = {}
        item.forEach((t) => {
          if(t.http_code !== 0  && !isNaN(t.total_time)){
            tmpTime.push(t.total_time)
          }
        })
        tmpTime.forEach(item =>{
          sum += item
        })
        let totalTimeItem = tmpTime.length
        let avgTime = sum/totalTimeItem
        tmpObject.locate = groupName
        tmpObject.total_time = avgTime.toFixed(4)
        tmpArray.push(tmpObject)
      })
      return tmpArray
    },
    // 根據geoJson,取得中國各省名稱 p.s. 目前沒用到
    getChinaState () {
      this.chinaGeoJSON.features.forEach((feature) => {
        this.chinaState.push({name:feature.properties.name, id:uuidv4()})
      })
    },
    // 根據geoJson篩掉, 非已選擇的位置, 存入filterdState p.s. 目前沒用到
    getSelectedLocation () {
      this.filterdState = this.chinaGeoJSON.features.filter((feature) => {
        // console.log('feature:', feature)
        let checkedName = ''
        this.checkedLocation.forEach((checked) => {
          if(feature.properties.name === checked.name) {
            console.log('feature.properties.name:',feature.properties.name)
            console.log('checked.name:', checked.name)
            checkedName = checked.name
          }
        })
        return feature.properties.name === checkedName
      })
      if(this.filterdState.length === 0){
        this.filterdState = this.chinaGeoJSON.features
      }
    },
    // 將資料匯入表單
    getChartsData () {
      this.chartOptions.xAxis.categories = []
      this.chartOptions.series[0].data = []
      this.results.forEach((item)=>{
        // console.log('item.http_code:', item.http_code)
        if(item.http_code){
          if(item.http_code !== 0){
            this.chartOptions.xAxis.categories.push(`${item.locate} ${item.line}`)
            this.chartOptions.series[0].data.push({y:item.total_time, color: this.getColor(item.total_time), showInLegend:false}) 
          }
        }
      })
    },
    // 取得顏色
    getColor(d) {
      return d > 3?
      "#FF4325": d > 2?
      "#ffc107": d < 2?
      '#16982B': 'black'
    },
    // 硬幹jq,消除leaflet的logo
    removeLeafletLogo () {
      const leafletLogo = $('.leaflet-control-attribution.leaflet-control')
      leafletLogo.css('display','none')
    },
    // 判斷 時間 回傳 不同顏色
    renderColor(d) {
      if( d > 3) {
        return `<span style="color:#FF4325;">${d}</span>`
      }
      if(d > 2) {
        return `<span style="color:#ffc107;">${d}</span>`
      }
      if(d < 2) {
        return `<span style="color:#16982B;">${d}</span>`
      }
    },
    clickAnchorToCopyUrl(){
        var textarea = document.createElement('textarea');
        textarea.textContent = this.anchor;
        document.body.appendChild(textarea);

        var selection = document.getSelection();
        var range = document.createRange();

        range.selectNode(textarea);
        selection.removeAllRanges();
        selection.addRange(range);

        console.log('copy success', document.execCommand('copy'));
        selection.removeAllRanges();

        document.body.removeChild(textarea);
        Toast.fire({
          title: 'copy !!!',
          icon: 'success',
          timer: 1000
        })
    },
    calculatePercentage() {
      const allIp = this.results.map(item => item.primary_ip || 'NX');
      const ipCounts = allIp.reduce((counts, ip) => {
        counts[ip] = (counts[ip] || 0) + 1;
        return counts;
      }, {});
      
      if(Object.prototype.hasOwnProperty.call(ipCounts, 'NX')) {
        console.log('true')
        this.totalIp = Object.keys(ipCounts).length - 1
      }else {
        console.log('false')
        this.totalIp = Object.keys(ipCounts).length
      }

      const totalCount = allIp.length;
      const percentages = {};
      for (const ip in ipCounts) {
        const count = ipCounts[ip];
        const percentage = (count / totalCount) * 100;
        percentages[ip] = percentage.toFixed(2);
      }
      const formattedData = Object.entries(percentages)
        .map(([ip, percent]) => ({ ip, percent }))
        .sort((a, b) => b.percent - a.percent);
      
      this.ipList = formattedData;
    },
    // 建立 cask id
    async createTask(){
      try {
        console.log(this.url)
        const result = await testingAPI.createTaskId({url:this.url})
        if(result.status === 200){
          console.log('create task', result)
          this.task = result.data
          this.getStatus()
          this.message = 'create task'
        }
      }
      catch(err){
        console.log(err)
      }
    },
    // 取得狀態
    async getStatus() {
      try {
        console.log('this.task.msg:', this.task.msg)
        clearInterval(this.timer)
        this.timer = null
        console.log(`into getStatus${this.try}`)
        if(this.try < 4) {
          const result = await testingAPI.fetchStatusCode({msg:this.task.msg})
          console.log(result)
          this.message = 'get status'
          if(result.data.msg === 'ok') {
            this.message = 'fetch data'
            clearInterval(this.timer)
            this.timer = null
            await this.fetchData()
            this.try = 0
          } else {
            if(this.try === 0){
              this.message = `first attempt`
            }
            if(this.try === 1){
              this.message = `second attempt`
            }
            if(this.try === 2){
              this.message = `third attempt`
            }
            if(this.try === 3){
              this.message = `fourth attempt`
            }
            
            this.timer = setInterval(()=>{
              console.log('timer:',this.timer);
              this.getStatus()
            }, 15000)
          this.try++
          }
        } else {
          this.message = 'over 60s'
          clearInterval(this.timer)
          this.timer = null
          this.fetchData()
          this.try = 0
        }
      }
      catch(err) {
        console.log(err)
      }
    }, 
    // 取得個節點資料
    async fetchData (){
      try {
        this.blockedAmount = 0
        this.results = []
        const result = await testingAPI.fetchNodeInfo({data:this.task})
        this.message = 'done'
        // results 需處理 
        const results = JSON.stringify(result.data)
        console.log('results:', results)
        const parseRsults = JSON.parse(results)
        this.parseRsultsMsg = JSON.parse(parseRsults.msg)
        this.parseRsultsMsg.forEach(item => {
          const tmp = {}
          const { locate, primary_ip, http_code, speed_download, total_time,line, id } = item
          tmp.id = id
          tmp.locate = locate
          tmp.line = line
          tmp.primary_ip = primary_ip
          tmp.http_code = http_code
          if(tmp.primary_ip === '' || !tmp.primary_ip || tmp.http_code === 0 || !tmp.http_code) {
            this.blockedAmount++
          }
          tmp.speed_download = speed_download
          tmp.total_time = total_time
          // this.results 為渲染的資料
          this.results.push(tmp)
        })
        console.log('this.parseRsultsMsg:', this.parseRsultsMsg)
        this.nowTime = this.parseRsultsMsg[0].create_time
        this.result_url = this.parseRsultsMsg[0].endpoint
        this.anchor = `${window.location.origin}/#${this.task.msg}`
        this.getChartsData()
        this.mappingGeoJsonAndResults()
        this.generateLeaflet()
        this.calculatePercentage()
        this.isLoading = false
      }
      catch(err) {
        console.log(err)
      }
    },
    clickLocation() {
      console.log('clickLocation')
      this.results = this.results.sort(function(a,b){
        console.log('a:',a)
        console.log('b:',b)
        return a.locate > b.locate ? 1 : -1;
      })
    }
  }
}
</script>

<style lang="scss">
@import './../assets/scss/speed_test.scss';

</style>