import { apiHelper } from "@/utils/helper"

export default {
    submitUrl(url){
        return apiHelper.get('/', {
            url
        })
    },
    createTaskId(url){
        return apiHelper.post('/createTask', 
            url
        )
    },
    fetchStatusCode(msg){
        return apiHelper.post('/checkStatus', 
            msg
        )
    },
    fetchNodeInfo({data}){
        return apiHelper.post('/fetchData', {
            data,
        })
    }
}