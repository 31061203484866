import axios from "axios";
import Swal from 'sweetalert2'
const url = window.location.origin
let baseURL = ''

if(url.indexOf('localhost') > -1) { 
    console.log('包含 localhost')
    baseURL = 'http://localhost:3000'
}else {
    baseURL = url
}

export const apiHelper = axios.create({
    baseURL
})

export const Toast = Swal.mixin({
    toast: true,
    iconColor: '#17a2b8',
    showCancelButton: false,
    confirmButtonColor: '#17a2b8',
    showConfirmButton: false,
    timer: 3000
})