<template>
    <SpeedTestVue />
</template>

<script>
// @ is an alias to /src
import SpeedTestVue from '@/components/SpeedTest.vue'
export default {
  name: 'HomeView',
  components: {
    SpeedTestVue
  }
}
</script>
