<template>
  <highcharts :options="chartOptions"></highcharts>
</template>
<script>
export default {
  data() {
    return {
      filterStateChild: this.filterState
    };
  },
  props:{
    chartOptions: Array
  },
  mounted() {
  }
};
</script>
